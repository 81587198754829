<template>
  <main class="container news-reader container-px-lg">
    <span class="breadcrumbs" style="color: gray">
      <router-link class="text-black" to="/">{{$t('crumbs.home')}}</router-link>
       / <router-link class="text-black" to="/news">{{$t('crumbs.blog')}}</router-link> / {{ $i18n.locale === 'ru' ? details.header : details.header_en || details.header }}
    </span>

    <div class="news-info d-flex flex-wrap mt-3 px-1 px-lg-0">
      <div class="news-date mr-1">
        {{ new Date(details.timestamp * 1000).toLocaleDateString($i18n.locale) }}
      </div>

      <div v-if="details.categories" class="categories d-flex flex-wrap">
        <div v-for="(topic, index) in details.categories" :key="index" class="category d-flex m-0 p-0 mr-1">
          <img src="@/assets/images/site/icons/shape_blue.svg"/>
          {{  $i18n.locale === 'ru' ? topic.title : topic.title_en || topic.title }}
        </div>
      </div>

      <div class="read-time">
        <span v-if="details.time_read" class="time_news text-right">
          <img src="@/assets/images/site/icons/time.svg"/>
          {{ $i18n.locale === 'ru' ? details.time_read : details.time_read_en || details.time_read }}
        </span>
      </div>
    </div>

    <div class="d-flex flex-column flex-lg-row">
      <div class="news-content mt-1 col-12 col-lg-9 pl-lg-0">
        <q-header level="1" :centered="false">
          <b>{{ $i18n.locale === 'ru' ? details.header : details.header_en || details.header }}</b>
        </q-header>

        <div class="news-picture">
          <img v-if="details.media" :src="details.media.urls.original" :alt="details.media_alt" :title="details.media_title" class="w-100">
        </div>

        <div v-if="$i18n.locale === 'en' && details.content_en" id="news-item-text" class="news-item-text mt-2" v-html="details.content_en">
        </div>
        <div v-else id="news-item-text" class="news-item-text mt-2" v-html="details.content">
        </div>
      </div>
      <div class="last-posts col-12 col-lg-3 pr-lg-0">
        <span class="last-posts-header">{{$t('news.latest-posts')}}</span>

        <div class="row">

          <div v-for="(newsItem, index) in lastNews.dataSource.items.slice(0, 4)" :key="index" class="col-12 col-md-6 col-lg-12">
            <SmallNewsCard :date="new Date(newsItem.timestamp * 1000)"
                           :slug="newsItem.slug"
                           :time="newsItem.time_read"
                           :timeEn="newsItem.time_read_en"
                           :title="newsItem.header"
                           :titleEn="newsItem.header_en"
                           :topics="newsItem.categories"/>
            <hr>
          </div>
        </div>

        <TelegramCard/>
      </div>
    </div>

    <div v-if="similar" class="similar mt-4 px-1 px-lg-0">
      <q-header :centered="false">
        <b>{{$t('news.read-more')}}</b>
      </q-header>

      <q-carousel v-if="similar.length > 0">
        <q-slide v-for="(newsItem, index) in similar" :key="index">
          <BlogCard
              :date="new Date(newsItem.timestamp * 1000)"
              :img="newsItem.media.urls.original"
              :slug="newsItem.slug"
              :timeToRead="newsItem.time_read"
              :timeToReadEn="newsItem.time_read"
              :title="newsItem.header"
              :titleEn="newsItem.header"
              :topics="newsItem.categories"
              :media_alt="newsItem.media_alt"
              :media_title="newsItem.media_title"
          />
        </q-slide>
      </q-carousel>
    </div>
  </main>
</template>

<script>
import SmallNewsCard from "@/views/Main/main-design-v2/views/news/components/SmallNewsCard";
import lastNews from "@/modules/contents/lastNews"
import TelegramCard from "@/views/Main/main-design-v2/views/news/components/TelegramCard";
import QCarousel from "@/views/Main/main-design-v2/components/QCarousel";
import QSlide from "@/views/Main/main-design-v2/components/QSlide";
import BlogCard from "@/views/Main/main-design-v2/views/news/components/BlogCard";

export default {
  metaInfo() {
    if (this.details) {
      let title = this.$i18n.locale === 'ru' ? this.details.header : this.details.header_en || this.details.header;
      title += ' | Qubity';
      const content = this.details.page_description;
      return {
        title: title,
        titleTemplate: null,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: content
          }
        ]
      }
    }
  },
  components: {BlogCard, QSlide, QCarousel, TelegramCard, SmallNewsCard},
  data() {
    return {
      details: null,
      similar: [],
      lastNews
    }
  },
  watch: {
    "$route.params.slug": function () {
      this.$request.get("news.get", {
        slug: this.$route.params.slug,
        locale: localStorage.getItem('current_locale') || 'ru'
      }).then(rsp => {
        this.details = rsp.item;
        this.similar = rsp.similar;
        // this.initContentViewer( JSON.parse(rsp.item.content) );
      }).catch(() => {
        this.$router.replace("/404")
      })
    }
  },
  created() {
    this.lastNews.get()

    this.$request.get("news.get", {
      slug: this.$route.params.slug,
      locale: localStorage.getItem('current_locale') || 'ru'
    }).then(rsp => {

      this.details = rsp.item;
      this.similar = rsp.similar;
    }).catch(() => {
      this.$router.replace("/404")
    })
  }
}
</script>

<style lang="scss" scoped>
.news-reader {
  min-height: 75vh;

  .breadcrumbs {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .news-info {
    div {
      margin-top: 6px;
    }
  }

  .categories {
    .category {
      color: var(--theme-accent-light);
      white-space: nowrap;

      img {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 2px;
      }
    }
  }

  .news-content {
    .news-picture {
      border-radius: 32px;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }
  }

  .read-time {
    color: #A9A9A9;
  }

  .last-posts-header {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .last-posts {
    .row {
      margin-top: 10px;
    }
  }
}
</style>

<style lang="scss">
.news-reader {
  .news-content {
    #news-item-text {
      font-size: 18px !important;

      h2 {
        font-size: 32px;
      }

      h3 {
        font-size: 24px;
      }

      h4 {
        font-size: 20px;
      }

      p, span, div {
        font-size: inherit !important;
        line-height: 160%;
      }
    }
  }

  .similar {
    .q-slide {
      height: 100%;
    }
  }
}
</style>
